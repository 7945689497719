<template>
  <section>
  <b-row>
    <b-col>
      <cosecha-y-produccion :disabled="true"></cosecha-y-produccion>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <tabla-lineas-planificacion-mensual></tabla-lineas-planificacion-mensual>
    </b-col>
  </b-row>
</section>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BButton } from "bootstrap-vue";


import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import CosechaYProduccion from "../../ajustes-restricciones/cosecha-y-produccion/CosechaYProduccion.vue";

import TablaLineasPlanificacionMensual from "./components/TablaLineasPlanificacionMensual.vue";

export default {
  props: {
    formInput: Object,
  },
  components: {
    BRow,
    BCol,
    BrunaForm,
    CosechaYProduccion,
    TablaLineasPlanificacionMensual
  },
  data()
  {
    return {






    };
  },

  methods: {



    validate()
    {
      return true
    }


  },

};
</script>
