<template>
 <b-row>
    <b-col>
      <BrunaForm
        ref="precioMercadoUsaRef"
        :fields="this.precioMercadoUsa.fields"
        :card="this.precioMercadoUsa.card"
        @change="precioMercadoUsaChange"
      >
        <template v-slot:footer> </template>
      </BrunaForm>
    </b-col>
  </b-row>

</template>

<script>
import { BRow, BCol, BFormGroup, BButton, BFormSelect } from "bootstrap-vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";


export default {
  props: {
  },
  components: {
    BRow,
    BCol,
    BrunaForm
  },


 methods: {


    getData()
    {
      return this.precioMercadoUsa
    },  

    precioMercadoUsaChange()
    {


    },

    validate()
    {
      return this.$refs.precioMercadoUsaRef.validate()
    }


  },



  data() {
    return {


      precioMercadoUsa: {
        card: {
          title: "Precios",
          subtitle: "",
          tooltip: ""
        },
        fields: [


          {
            label: "Precios USA UB",
            type: "select",
            id: 'precio_usa_ub',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "USA Proyeccion" }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

        



        ]
      },


    };
  },
};
</script>
