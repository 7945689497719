<template>
<section>

  <b-row>
    <b-col>
      <BrunaForm
        v-if="this.dataValidada.ultimoDatoDisponible.fields"
        :fields="this.dataValidada.ultimoDatoDisponible.fields"
        :card="this.dataValidada.ultimoDatoDisponible.card"
        :editable="false"
      >
        <template v-slot:footer> </template>
      </BrunaForm>
    </b-col>
  </b-row>
  <b-row>
    <b-col>

      <BrunaForm
        v-if="this.dataValidada.precioMercadoUsa.fields"
        :fields="this.dataValidada.precioMercadoUsa.fields"
        :card="this.dataValidada.precioMercadoUsa.card"
        :editable="false"
      >
        <template v-slot:footer> </template>
      </BrunaForm>


    </b-col>
  </b-row>



</section>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BButton } from "bootstrap-vue";


import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

export default {
 props: {

    dataValidada: {
      type: Object,
      required: true,
    },

   


  },

  components: {
    BRow,
    BCol,
    BrunaForm
  },
  data()
  {
    return {



    


    };
  },

  methods: {



    validate()
    {
      return true
    }


  },

};
</script>
