<template>
  <b-row>
    <b-col>
      <BrunaForm
        ref="ultimoDatoDisponibleRef"
        :fields="this.ultimoDatoDisponible.fields"
        :card="this.ultimoDatoDisponible.card"
        @change="ultimoDatoDisponibleChange"
      >
        <template v-slot:footer> </template>
      </BrunaForm>
    </b-col>
  </b-row>

</template>

<script>
import
{

  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
} from "bootstrap-vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

export default {
  props: {
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BrunaForm
  },
  data()
  {
    return {



      ultimoDatoDisponible: {
        card: {
          title: "Orígenes de datos disponibles",
          subtitle: "Selecciona el origen de datos que utilizaras para la planificación",
          tooltip: ""
        },
        fields: [

            {
            label: "Nombre de Planificación",
            type: "input",
            id: 'planification_name',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Stock Materia Prima",
            type: "select",
            id: 'escenarios_afu',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "Stock POC" }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          /*
          {
            label: "Reporte Stock",
            type: "select",
            id: 'reporte_stock',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "RStock2021-08-01" },
              { value: 2, text: "RStock2020_FK" },
              { value: 3, text: "RStock2021_FK" },
              { value: 4, text: "RStock2019_FK" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },
        */

          {
            label: "Stock Acopio",
            type: "select",
            id: 'reporte_stock',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "No Aplica" }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Stock en Tránsito",
            type: "select",
            id: 'stock_en_transito',
            rules: 'required', 
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "No Aplica" }
            ],
            format: {
              xs: 4,
              sm: 4, 
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Demanda",
            type: "select",
            id: 'costo_std',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "Demanda Stock" }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



        ]
      },


    };
  },
  computed: {

  },
  methods: {

    ultimoDatoDisponibleChange()
    {


    },

    validate()
    {
      return this.$refs.ultimoDatoDisponibleRef.validate()
    },

    getData()
    {
      return this.ultimoDatoDisponible
    }    


  },


};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>